@import "../../../../scss/theme-bootstrap";

.country-selection-popup {
  text-align: center;
  &__header {
    font-size: 26px;
    line-height: 1.3;
    font-weight: bolder;
    text-transform: uppercase;
    @include breakpoint($medium-up) {
      font-size: 30px;
    }
  }
  &__text {
    margin: 14px 0 20px 0;
    @include breakpoint($medium-up) {
      margin: 20px 0;
    }
    p {
      margin: 0;
      display: inline;
      @include breakpoint($medium-up) {
        display: block;
      }
    }
  }
  &__button {
    background-color: #000;
    padding: 10px 0;
    a {
      color: #fff;
    }
  }
  &__continue-section {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 14px;
    @include breakpoint($medium-up) {
      margin-top: 20px;
    }
  }
  &__link {
    margin-right: 5px;
  }
  // normalize with regular text
  a,
  .button {
    font-size: 12px;
    @include breakpoint($medium-up) {
      font-size: 14px;
    }
  }
}

.country-selection-popup-cbox {
  #cboxLoadedContent {
    padding: 40px 20px 40px 20px;
    @include breakpoint($medium-up) {
      padding: 40px 60px 30px 60px;
    }
  }
}
